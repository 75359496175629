<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>上架管理</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item">
              <label>查询：</label>
              <el-input
                placeholder="请输入藏品名称、藏品集或品牌方"
                v-model="query.content"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item">
              <label>审核状态：</label>
              <el-select placeholder="请选择审核状态" v-model="query.status">
                <el-option label="待审核" value="2"></el-option>
                <el-option label="审核通过" value="3,5,6,7"></el-option>
                <el-option label="审核失败" value="4"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
          <el-button type="primary" size="small" class="fr" @click="goCreate"
            >藏品上架</el-button
          >
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="id" label="藏品ID" width="120">
            </el-table-column>
            <el-table-column prop="brandName" label="品牌方" align="center">
            </el-table-column>
            <el-table-column
              prop="collectionName"
              label="藏品集名称"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="NFTName"
              label="藏品名称"
              width="150"
            ></el-table-column>
            <el-table-column prop="onlineCount" label="上架数量" align="center">
            </el-table-column>

            <el-table-column prop="sellCount" label="出售数量" align="center">
            </el-table-column>
            <el-table-column
              prop="unitPrice"
              label="单价（单位：元）"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="onsellTime"
              label="开售时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="onlineTime"
              label="上架时间"
              align="center"
            ></el-table-column>
            <el-table-column prop="status" label="审核状态" align="center">
              <template slot-scope="scope">
                <span class="font-warning" v-if="scope.row.status == 1"
                  >预留</span
                >
                <span class="font-warning" v-else-if="scope.row.status == 2"
                  >待审核</span
                >

                <span
                  class="font-success"
                  v-else-if="
                    scope.row.status == 3 ||
                    scope.row.status == 5 ||
                    scope.row.status == 6 ||
                    scope.row.status == 7 ||
                    scope.row.status == 8 ||
                    scope.row.status == 9
                  "
                  >审核通过</span
                >
                <span class="font-danger" v-else-if="scope.row.status == 4"
                  >审核失败</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="goUpdate(scope.row.id)"
                    >编辑</el-link
                  >
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="goDetail(scope.row.id)"
                    >查看上架详情</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MarketQuery } from "@/api/module/market";

export default {
  name: "MarketIndex",
  data() {
    return {
      showLoading: false,
      query: {
        status: "",
        content: "",
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },

  created() {
    this.doQuery();
  },
  methods: {
    goUpdate(id) {
      this.$router.push(`/market/update/${id}`);
    },
    // 查询
    doQuery() {
      let query = this.setQueryParams();
      MarketQuery(query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },

    // 设置筛选
    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.query.content) {
        query += `&like=${this.query.content}`;
      }
      if (this.query.status) {
        query += `&status=${this.query.status}`;
      }
      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        content: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 去新增
    goCreate() {
      this.$router.push("/market/create");
    },

    // 去编辑
    goDetail(id) {
      this.$router.push(`/market/detail/${id}`);
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>



