var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"x-wrap"},[_vm._m(0),_c('div',{staticClass:"x-main"},[_c('div',{staticClass:"x-q-panel"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"x-con-item"},[_c('label',[_vm._v("查询：")]),_c('el-input',{attrs:{"placeholder":"请输入藏品名称、藏品集或品牌方"},model:{value:(_vm.query.content),callback:function ($$v) {_vm.$set(_vm.query, "content", $$v)},expression:"query.content"}})],1)]),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"x-con-item"},[_c('label',[_vm._v("审核状态：")]),_c('el-select',{attrs:{"placeholder":"请选择审核状态"},model:{value:(_vm.query.status),callback:function ($$v) {_vm.$set(_vm.query, "status", $$v)},expression:"query.status"}},[_c('el-option',{attrs:{"label":"待审核","value":"2"}}),_c('el-option',{attrs:{"label":"审核通过","value":"3,5,6,7"}}),_c('el-option',{attrs:{"label":"审核失败","value":"4"}})],1)],1)]),_c('el-col',{attrs:{"span":3}},[_c('el-button',{staticClass:"x-q-btn",attrs:{"type":"primary"},on:{"click":function($event){_vm.query.pageIndex = 1;
              _vm.doQuery();}}},[_vm._v("筛选")]),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.doQueryReset}},[_vm._v("重置")])],1)],1)],1),_c('div',{staticClass:"x-table-container"},[_c('div',{staticClass:"x-table-caption"},[_c('el-button',{attrs:{"icon":"el-icon-refresh","type":"primary","plain":"","size":"small"},on:{"click":_vm.doRefresh}},[_vm._v("刷新")]),_c('el-button',{staticClass:"fr",attrs:{"type":"primary","size":"small"},on:{"click":_vm.goCreate}},[_vm._v("藏品上架")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.showLoading),expression:"showLoading"}],staticClass:"x-table",attrs:{"element-loading-text":"数据刷新中..."}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"id","label":"藏品ID","width":"120"}}),_c('el-table-column',{attrs:{"prop":"brandName","label":"品牌方","align":"center"}}),_c('el-table-column',{attrs:{"prop":"collectionName","label":"藏品集名称","width":"150"}}),_c('el-table-column',{attrs:{"prop":"NFTName","label":"藏品名称","width":"150"}}),_c('el-table-column',{attrs:{"prop":"onlineCount","label":"上架数量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"sellCount","label":"出售数量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"unitPrice","label":"单价（单位：元）","align":"center"}}),_c('el-table-column',{attrs:{"prop":"onsellTime","label":"开售时间","align":"center"}}),_c('el-table-column',{attrs:{"prop":"onlineTime","label":"上架时间","align":"center"}}),_c('el-table-column',{attrs:{"prop":"status","label":"审核状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status == 1)?_c('span',{staticClass:"font-warning"},[_vm._v("预留")]):(scope.row.status == 2)?_c('span',{staticClass:"font-warning"},[_vm._v("待审核")]):(
                  scope.row.status == 3 ||
                  scope.row.status == 5 ||
                  scope.row.status == 6 ||
                  scope.row.status == 7 ||
                  scope.row.status == 8 ||
                  scope.row.status == 9
                )?_c('span',{staticClass:"font-success"},[_vm._v("审核通过")]):(scope.row.status == 4)?_c('span',{staticClass:"font-danger"},[_vm._v("审核失败")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex-box-around"},[_c('el-link',{attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.goUpdate(scope.row.id)}}},[_vm._v("编辑")]),_c('el-link',{attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.goDetail(scope.row.id)}}},[_vm._v("查看上架详情")])],1)]}}])})],1),_c('div',{staticClass:"x-pager right"},[_c('el-pagination',{attrs:{"background":"","layout":"sizes, prev, pager, next","current-page":_vm.query.pageIndex,"page-size":_vm.query.pageSize,"page-sizes":[10, 20, 30, 50],"total":_vm.total},on:{"update:currentPage":function($event){return _vm.$set(_vm.query, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.query, "pageIndex", $event)},"size-change":_vm.setPageSize,"current-change":_vm.setPageIndex}})],1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"x-page-title"},[_c('h3',[_vm._v("上架管理")])])}]

export { render, staticRenderFns }